import { Text, type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, DialogContent, Stack } from "@mui/material";
import { FullScreenDialog } from "@src/appV2/lib/Dialogs";
import { useState } from "react";

import { Button } from "../../components/Button";
import { type PreferredCandidateAreaForm } from "../types";
import { AddressSelectionMap } from "./AddressSelectionMap";

interface AddressSelectionDialogProps {
  modalState: UseModalState;
  onAddressSelect: (addressPreference: PreferredCandidateAreaForm) => void;
  initialAddressDetails: PreferredCandidateAreaForm;
}

export function AddressSelectionDialog(props: AddressSelectionDialogProps) {
  const { modalState, onAddressSelect, initialAddressDetails } = props;
  const [addressPreference, setAddressPreference] =
    useState<PreferredCandidateAreaForm>(initialAddressDetails);

  const handleSave = () => {
    if (addressPreference) {
      onAddressSelect(addressPreference);
      modalState.closeModal();
    }
  };

  const handleCancel = () => {
    setAddressPreference(initialAddressDetails);
    modalState.closeModal();
  };

  const handleAddressChange = ({
    address,
    location,
    distance,
  }: Partial<PreferredCandidateAreaForm>) => {
    setAddressPreference((previousAddressPreference) => ({
      address: address ?? previousAddressPreference.address,
      location: {
        lat: location?.lat ?? previousAddressPreference.location.lat,
        lng: location?.lng ?? previousAddressPreference.location.lng,
      },
      distance: distance ?? previousAddressPreference.distance,
    }));
  };

  return (
    <FullScreenDialog modalState={modalState}>
      <DialogContent>
        <Stack spacing={2}>
          <Text variant="h4">
            You can move the pin below to let workplaces know where you&apos;re interested in
            working
          </Text>
          <Box sx={{ height: "calc(100vh - 240px)" }}>
            <AddressSelectionMap
              location={{
                lng: addressPreference.location.lng,
                lat: addressPreference.location.lat,
              }}
              address={addressPreference.address}
              distance={addressPreference.distance}
              onAddressChange={handleAddressChange}
            />
          </Box>
          {addressPreference.address && (
            <Box>
              <Text variant="subtitle1">Selected Address:</Text>
              <Text variant="body1">{addressPreference.address.formatted}</Text>
            </Box>
          )}
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button size="small" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              disabled={!isDefined(addressPreference.address)}
              onClick={handleSave}
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </FullScreenDialog>
  );
}
