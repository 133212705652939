import {
  Box,
  FormGroup,
  FormLabel,
  GlobalStyles,
  InputAdornment,
  // TODO: Use our own Slider
  // eslint-disable-next-line no-restricted-imports
  Slider,
  Stack,
  // TODO: Use our own Input
  // eslint-disable-next-line no-restricted-imports
  TextField,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { Autocomplete, Circle, GoogleMap, Marker } from "@react-google-maps/api";
import {
  type Address,
  LA_CENTER,
  type Location,
  parseAddressComponents,
} from "@src/appV2/lib/GoogleMaps";
import { useRef, useState } from "react";

import { MILE_TO_METER } from "../constants";
import { type PreferredCandidateAreaForm } from "../types";

interface AddressSelectionMapProps {
  location: Location;
  address: Address;
  distance: number;
  onAddressChange: ({ address, location, distance }: Partial<PreferredCandidateAreaForm>) => void;
}

export function AddressSelectionMap(props: AddressSelectionMapProps) {
  const { location, address, distance, onAddressChange } = props;
  const [inputValue, setInputValue] = useState(address?.formatted ?? "");
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | undefined>(
    undefined
  );
  const mapRef = useRef<google.maps.Map | undefined>(undefined);

  function updateMapBounds() {
    if (mapRef.current && location) {
      const bounds = new google.maps.LatLngBounds();
      const center = new google.maps.LatLng(location.lat, location.lng);
      bounds.extend(center);
      const radiusInMeters = distance * MILE_TO_METER;
      const northEast = google.maps.geometry.spherical.computeOffset(center, radiusInMeters, 45);
      const southWest = google.maps.geometry.spherical.computeOffset(center, radiusInMeters, 225);
      bounds.extend(northEast);
      bounds.extend(southWest);
      mapRef.current.fitBounds(bounds);
    }
  }

  function updateAddressAndLocation(
    place: google.maps.GeocoderResult | google.maps.places.PlaceResult,
    location: Location
  ) {
    const newAddress = {
      ...parseAddressComponents(place.address_components ?? []),
      formatted: place.formatted_address,
    };
    onAddressChange({ address: newAddress, location });
    setInputValue(newAddress.formatted ?? "");
  }

  return (
    <Box sx={{ width: "100%", height: "100%", position: "relative" }}>
      <GoogleMap
        mapContainerStyle={{ width: "100%", height: "100%" }}
        center={location ?? LA_CENTER}
        zoom={15}
        onClick={(event: google.maps.MapMouseEvent) => {
          if (event.latLng) {
            const clickedLocation: Location = {
              lat: event.latLng.lat(),
              lng: event.latLng.lng(),
            };
            const geocoder = new google.maps.Geocoder();
            geocoder
              .geocode({ location: clickedLocation })
              .then((results) => {
                if (results.results[0]) {
                  updateAddressAndLocation(results.results[0], clickedLocation);
                  updateMapBounds();
                }
              })
              .catch((_) => {
                // Handle error
              });
          }
        }}
        onLoad={(map: google.maps.Map) => {
          mapRef.current = map;
          updateMapBounds();
        }}
      >
        <Marker
          draggable
          position={location ?? LA_CENTER}
          onDragEnd={(event: google.maps.MapMouseEvent) => {
            if (event.latLng) {
              const newLocation: Location = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
              };
              const geocoder = new google.maps.Geocoder();
              geocoder
                .geocode({ location: newLocation })
                .then((results) => {
                  if (results.results[0]) {
                    updateAddressAndLocation(results.results[0], newLocation);
                    updateMapBounds();
                  }
                })
                .catch((_) => {
                  // Handle error
                });
            }
          }}
        />
        <Circle
          center={location ?? LA_CENTER}
          radius={distance * 1609.34}
          options={{
            fillColor: blue[500],
            fillOpacity: 0.2,
            strokeColor: blue[500],
            strokeOpacity: 0.8,
            strokeWeight: 2,
          }}
        />
      </GoogleMap>

      <GlobalStyles
        styles={{
          ".pac-container": {
            zIndex: 1300,
          },
        }}
      />

      <Box
        sx={{
          position: "absolute",
          bottom: 10,
          left: 10,
          right: 10,
          backgroundColor: "white",
          padding: 2,
          borderRadius: 1,
        }}
      >
        <Stack spacing={2}>
          <FormGroup>
            <FormLabel>Search for an address</FormLabel>
            <Autocomplete
              onLoad={setAutocomplete}
              onPlaceChanged={() => {
                const place = autocomplete?.getPlace();
                if (place?.geometry?.location) {
                  const newLocation: Location = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                  };
                  updateAddressAndLocation(place, newLocation);
                }
              }}
            >
              <TextField
                fullWidth
                hiddenLabel
                aria-label="Search for an address"
                type="search"
                placeholder="Search for an address"
                size="small"
                value={inputValue}
                onChange={(event) => {
                  setInputValue(event.target.value);
                }}
              />
            </Autocomplete>
          </FormGroup>

          <FormGroup>
            <FormLabel>Distance you are willing to travel from this address</FormLabel>
            <TextField
              fullWidth
              hiddenLabel
              type="number"
              aria-label="Distance from address"
              value={distance}
              InputProps={{
                endAdornment: <InputAdornment position="end">miles</InputAdornment>,
                inputProps: {
                  min: 1,
                  max: 150,
                },
                readOnly: true,
              }}
              size="small"
            />
            <Slider
              value={distance}
              min={1}
              max={150}
              step={1}
              valueLabelDisplay="auto"
              onChange={(_, newValue: number | number[]) => {
                const newRadius = Array.isArray(newValue) ? newValue[0] : newValue;
                onAddressChange({ distance: newRadius });
                updateMapBounds();
              }}
            />
          </FormGroup>
        </Stack>
      </Box>
    </Box>
  );
}
